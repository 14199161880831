<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 header-col">
        <h1 class="title-w1">Ecodesign Evaluator</h1>
        <app-info title="Ecodesign Evaluator">
          <p>
            This tool is
            <span class="text-bold"
              >aimed at data centre equipment designers.</span
            >
          </p>
          <p>
            The Ecodesign Evaluator is a set of
            <span class="text-bold">Ecodesign Criteria</span>
            Evaluator is a set of Ecodesign Criteria consolidated in one place,
            making it much easier for a designer to follow. The tool includes
            Ecodesign guidelines outlined by the
            <a
              href="https://ec.europa.eu/environment/pdf/circular-economy/new_circular_economy_action_plan.pdf"
              target="_blank"
              class="text-underlined"
              >EU Circular Economy Action Plan 2020</a
            >
            and
            <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32019R0424&from=EN"
              target="_blank"
              class="text-underlined"
              >Commission Regulation (EU) 2019/424 Lot 9</a
            >, and bespoke Ecodesign criteria based on the empirical data collected by CEDaCI.
          </p>
          <p>
            <span class="text-bold">Check</span> the overall circularity of your
            server design by
            <span class="text-bold">answering questions</span> about various
            criteria considered important for circularity.
          </p>
          <p>
            Note
            <span class="text-bold text-red"
              >that fields marked with an asterisk (*) are mandatory. </span
            >These requirements are determined by the EU Circular Economy Action Plan 2020 and Commission Regulation (EU) 2019/424.
          </p>
        </app-info>
      </div>
    </div>
    <EdgEvaluator />
  </div>
</template>

<script>
import EdgEvaluator from '@/components/evaluate/EdgEvaluator';
export default {
  name: 'Evaluate',
  components: {
    EdgEvaluator,
  },
};
</script>

<style lang="scss">
.header-col {
  display: flex;
  margin-bottom: 1rem;
}
</style>
